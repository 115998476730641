import React from 'react';
import Button from 'components/Core/Button';

import { FormContainer } from '../../styles';
import { ROIResultContainer } from '../styles';

export const ROIResult = ({ results = [], ...otherProps }) => {
  return (
    <FormContainer {...otherProps}>
      <div className="section-tool-page-form--wrapper">
        <div className="section-tool-page-form--container" style={{ maxWidth: '100%' }}>
          <ROIResultContainer className="section-roi-result-hero--main">
            <div className="section-roi-result-hero--result-container">
              {results &&
                results.map((item, index) => {
                  return (
                    <div
                      className={
                        'section-roi-result-hero--result-item' + (item.active ? ' active' : '')
                      }
                      key={index}
                    >
                      <p className="section-roi-result-hero--result-item-title font-heading-md">
                        {item.title}
                      </p>
                      <h2 className="section-roi-result-hero--result-item-value font-h1">
                        {item.value}
                      </h2>
                    </div>
                  );
                })}
            </div>
            <div className="section-roi-result-hero--share-outer-container">
              <h2 className="section-roi-result-hero--share-title font-heading-lg">
                Book a demo to learn how to grow your business with ServiceTitan
              </h2>
              <div className="section-roi-result-hero--share-container">
                <Button to="/demo">Get Demo</Button>
              </div>
            </div>
          </ROIResultContainer>
        </div>
      </div>
    </FormContainer>
  );
};

export default ROIResult;
