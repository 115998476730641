import React from 'react';
import { graphql } from 'gatsby';
import ToolboxLayout from 'layouts/toolboxLayout';
import isNil from 'utils/isNil';
import Hero from 'components/Tools/Hero';
import ROIResult from 'components/Tools/ROICalculator/Result';
import ROIResultHow from 'components/Tools/ROICalculator/ResultHow';
import DemoFormSection from 'components/Sections/DemoFormSection';
import { calculateROI } from 'components/Tools/ROICalculator/calculations';
import { globalHistory } from '@reach/router';
import { createToolsBreadcrumbs } from './utils/createToolsBreadcrumbs';
import SocialSharing from 'components/SocialSharing';

const ROICalculatorResultPage = (props) => {
  const data = props.data.contentfulTool;
  const breadcrumbs = createToolsBreadcrumbs('ROI Calculator');

  const paramName = [
    'company',
    'techs',
    'officeStaff',
    'revenue',
    'calls',
    'estimates',
    'leads',
    'jobs',
    'industry',
    'otherIndustry',
    'existingCustomer',
  ];

  const getUrlVars = (params) => {
    const vars = {};
    `&${params}`.replace(/[?&]+([^=&]+)=([^&]*)/gi, (m, key, value) => {
      vars[paramName[key.slice(1)]] = value;
    });
    return vars;
  };

  const calcResults = (navigateParams) => {
    if (
      !isNil(globalHistory) &&
      !isNil(globalHistory.location) &&
      !isNil(globalHistory.location.href)
    ) {
      const params = globalHistory.location.href.split('?');
      if (navigateParams || params.length > 1) {
        const results = calculateROI(getUrlVars(navigateParams || params[1]));

        dataLayer.push({
          event: 'calculator',
          calculator_type: 'ROI Calculator - results',
          roiWithST: results[0].value,
          monthlyRevenueIncrease: results[1].value,
          annualRevenueIncrease: results[2].value,
        });

        return results;
      }
    }

    return [
      {
        title: 'ROI with ServiceTitan',
        value: '0%',
        active: false,
      },
      {
        title: 'Monthly Revenue Increase',
        value: '$0k',
        active: true,
      },
      {
        title: 'Annual Revenue Increase',
        value: '0%',
        active: false,
      },
    ];
  };

  const results = calcResults(props?.location?.state?.params);

  return (
    <ToolboxLayout
      breadcrumbs={breadcrumbs}
      contentfulSeo={{
        ...data.seo,
        pageUrl: 'tools/roi-calculator-results',
        pageTitle: 'ROI Calculator Result | ServiceTitan',
      }}
      layout="toolbox"
      {...props}
    >
      <Hero data={{ ...data.hero, heading: `Your ROI with ServiceTitan.`, subhead: undefined }} />
      <ROIResult results={results} />
      <ROIResultHow
        background="var(--skyline-1)"
        title="How can ServiceTitan increase revenue?"
        cards={data?.cardsBlock?.cards}
      />
      <DemoFormSection
        backgroundColor="var(--dark)"
        formTitle="Let’s get started."
        description="Explore why 100,000+ contractors trust Service Titan to run their business."
        title="Book a personalized demo today."
      />
    </ToolboxLayout>
  );
};

export default ROICalculatorResultPage;

export const ROICalculatorResultPageQuery = graphql`
  query ROICalculatorResultPageQuery {
    contentfulTool(internalName: { eq: "ROI Calculator" }) {
      ...Tool
    }
  }
`;
